import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getAds,
  getCallLetters,
  getCurrentStation,
  getGenreNames,
  getHasLocalSocial,
  getLiveStationPath,
  getSeedId,
  getStationSite,
} from 'state/Live/selectors';
import { getAsyncData, pageInfo } from './statics';
import { getStationLoaded } from 'state/Player/selectors';

const LiveProfile = loadable(() => import('./LiveProfile'));

LiveProfile.getAsyncData = getAsyncData;
LiveProfile.pageInfo = pageInfo;

export default connect(
  createStructuredSelector({
    ads: getAds,
    callLetters: getCallLetters,
    currentStation: getCurrentStation,
    hasLocalSocial: getHasLocalSocial,
    pagePath: getLiveStationPath,
    seedId: getSeedId,
    stationLoaded: getStationLoaded,
    stationSite: getStationSite,
    genres: getGenreNames,
  }),
)(LiveProfile);
