if (__DEV__ && module.hot) module.hot.accept();

import analytics from 'modules/Analytics';
import chromecast from 'web-player/chromecast';
import ClientRouter from 'router/ClientRouter';
import countryCodes from 'constants/countryCodes';
import factory from 'state/factory';
import hub from 'shared/utils/Hub';
import logger, { CONTEXTS } from 'modules/Logger';
import player from 'web-player';
import ReactDOM from 'react-dom';
import setupAuthListeners from 'shims/setupAuthListeners';
import title from 'ui/Title';
import { authenticate } from 'state/Session/actions';
import { createElement } from 'react';
import { getCountryCode } from 'state/Config/selectors';
import { getEnv } from 'state/Environment/selectors';
import { loadableReady } from '@loadable/component';
import { queryForABTestGroup } from 'state/ABTestGroups/actions';
import { requestCurrentLocationAndMarket } from 'state/Location/helpers';

async function main() {
  const store = factory();
  const state = store.getState();
  const env = getEnv(state);

  if (env !== 'prod') {
    hub.on('all', (evName: string, ...args: Array<any>) => {
      switch (evName) {
        case 'ihrplayer:status:adtime':
        case 'ihrplayer:status:time':
        case 'ihrplayer:status:liveRawMeta':
          return;
        default:
          logger.info([CONTEXTS.HUB, evName], args);
      }
    });
  }

  requestCurrentLocationAndMarket(store);

  setupAuthListeners();

  window.app = {
    analytics,
    chromecast,
    logger,
    player,
    title,
  };

  await loadableReady();

  await store.dispatch(authenticate());

  // AMP's AB test endpoint does not support WW
  // and we don't need AB test support for WW
  if (getCountryCode(state) !== countryCodes.WW) {
    /* For each user we need to place them into an abTestGroup. */
    await store.dispatch(queryForABTestGroup());
  }

  ReactDOM.hydrate(
    createElement(ClientRouter),
    document.getElementById('page-view-container'),
  );

  /**
   * After we have loaded entitlements we have all the global data we need
   * make analytics calls. At this point we can set our analytics readyState to
   * true.
   */
  analytics.setReadyState(true);

  const SEVENTY_TWO_HOURS = 259200 * 1000;

  player.setupTimer(SEVENTY_TWO_HOURS, SEVENTY_TWO_HOURS);
}

main();
